import React, { CSSProperties } from "react"
import {
  Section,
  Container,
  M,
  H2BlackTitleMargin,
  FlexRow,
  GrayBorder,
  PillTag,
  PillTagsContainer,
} from "../../Global"
import {
  TrainerViewGrid,
  DescriptionWrapper,
  P,
  ImageWrapper,
  GraySpan,
  SocialIconWrapper,
} from "./styles"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel as Slider } from "react-responsive-carousel"
import { SliderArrow } from "../../Elements/SliderArrow"
import facebookIcon from "../../../data/images/svg/facebook.svg"
import instagramIcon from "../../../data/images/svg/instagram.svg"
import linkedinIcon from "../../../data/images/svg/linkedin.svg"
import Package from "../../Elements/Package"
const indicatorStyles: CSSProperties = {
  background: "gray",
  width: 16,
  height: 16,
  display: "inline-block",
  margin: "0 8px",
}

const TrainerComponent = () => {
  return (
    <>
      <Section>
        <Container>
          <M>
            <H2BlackTitleMargin>TRENING ABS</H2BlackTitleMargin>
            <TrainerViewGrid>
              <ImageWrapper>
                <Slider
                  showThumbs={false}
                  autoPlay={false}
                  interval={15000}
                  infiniteLoop={true}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <SliderArrow
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          left: 15,
                          borderTop: "3px solid ",
                          borderLeft: "3px solid ",
                        }}
                      />
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <SliderArrow
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          right: 15,
                          borderBottom: "3px solid",
                          borderRight: "3px solid ",
                        }}
                      />
                    )
                  }
                  renderIndicator={(
                    onClickHandler,
                    isSelected,
                    index,
                    label
                  ) => {
                    if (isSelected) {
                      return (
                        <li
                          style={{ ...indicatorStyles, background: "#000" }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                        />
                      )
                    }
                    return (
                      <li
                        style={indicatorStyles}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                      />
                    )
                  }}
                >
                  <div
                    style={{
                      height: "595px",
                      width: "445px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "green",
                        height: "545px",
                        width: "445px",
                        position: "absolute",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      height: "595px",
                      width: "445x",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "blue",
                        height: "545px",
                        width: "445px",
                        position: "absolute",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      height: "595px",
                      width: "445px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "red",
                        height: "545px",
                        width: "445px",
                        position: "absolute",
                      }}
                    ></div>
                  </div>
                </Slider>
              </ImageWrapper>
              <DescriptionWrapper>
                <PillTagsContainer>
                  <PillTag>CARDIO</PillTag>
                  <PillTag>INTERWAŁ</PillTag>
                  <PillTag>BODYSHAPE</PillTag>
                  <PillTag>CARDIO</PillTag>
                  <PillTag>INTERWAŁ</PillTag>
                  <PillTag>BODY SHAPE</PillTag>
                </PillTagsContainer>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                </P>
                <GraySpan>TRENERA ZNAJDZIESZ RÓWNIEŻ NA</GraySpan>
                <FlexRow>
                  <SocialIconWrapper>
                    <img src={facebookIcon} />
                  </SocialIconWrapper>
                  <SocialIconWrapper>
                    <img src={instagramIcon} />
                  </SocialIconWrapper>
                  <SocialIconWrapper>
                    <img src={linkedinIcon} />
                  </SocialIconWrapper>
                </FlexRow>
              </DescriptionWrapper>
            </TrainerViewGrid>
          </M>
        </Container>
      </Section>
      <GrayBorder />
      <Section>
        <Container>
          <M>
            <Package />
            <Package />
          </M>
        </Container>
      </Section>
    </>
  )
}

export default TrainerComponent
