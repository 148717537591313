import styled from "styled-components"

export const TrainerViewGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 445px auto;
  grid-template-rows: 1fr;
  grid-template-areas: ". . ";
`
export const DescriptionWrapper = styled.div`
  padding: 0 15px 0 40px;
  display: flex;
  flex-direction: column;
`
export const P = styled.p`
  line-height: 30px;
`

export const GraySpan = styled.span`
  color: gray;
  font-size: 18px;
`

export const ImageWrapper = styled.div`
  width: 445px;
  height: 580px;
`

export const SocialIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-right: 20px;
  position: relative;
  & > img {
    transition: 0.3s;
    position: absolute;
    width: 40px;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
  }
`
