import React, { ReactElement } from "react"
import Layout from "../components/UI/Layout"
import TrainerTemplate from "../components/Sections/TrainerTemplate"
import Head from "../components/Global/Head"

const TrainerTemplatePage = ({ pageContext }: any): ReactElement => {
  return (
    <Layout hideLanding={true}>
      <Head
        pageTitle="Platforma Treningowa Online" //todo
        description="" //todo
        keywords="trening online, platforma treningowa online, video trening" //todo
      />
      <TrainerTemplate />
    </Layout>
  )
}

export default TrainerTemplatePage
